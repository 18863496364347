import ScreenReaderAnnouncer, {
  useScreenReaderAnnouncement,
} from '@rsa-digital/evo-shared-components/components/ScreenReaderAnnouncer';
import { scrollAndFocusInput } from '@rsa-digital/evo-shared-components/helpers/forms/scrollAndFocusError';
import useValidationWithWarnings from '@rsa-digital/evo-shared-components/helpers/forms/useValidationWithWarnings';
import { AssumptionId } from 'businessLogic/aggregatorAssumptions';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import React, { useEffect } from 'react';
import useAssumptions from 'components/CheckYourDetails/AggregatorAssumptionsSection/assumptions';
import FormFooter from 'components/FormFooter';
import { updateItem } from 'helpers/arrayHelpers';
import { PageTitle, trackFieldError, trackTextButtonClick } from 'helpers/eventTracking';
import { scrollAndTrackError } from 'helpers/forms';
import { petNameReplacer } from 'helpers/placeholders/dynamicPetNameHelpers';
import useUpdateNumberOfPets from 'helpers/useUpdateNumberOfPets';
import { useAssumptionsAgreement } from 'state/formData/assumptionsAgreement';
import { PetsDetails, usePetsDetails } from 'state/formData/petsDetails';
import AboutYourPetForm from './AboutYourPet';
import { showAdditionalPetBanner } from './AboutYourPet/gradualRevealHelpers';
import useAboutYourPetRules from './AboutYourPet/validation';
import {
  AboutYourPetFormAdditionalSectionBanner,
  AboutYourPetFormRepeatableSection,
  ThreePetAddedBannerSection,
} from './styles';
import { usePetDetailsOptions } from './usePetsDetailsOptions';

type AboutYourPetsFormProps = {
  moveNext: () => void;
};

type AboutYourPetFormData = {
  csPetAboutYourPetV2: {
    next_button_text: string;
  };
};

export const query = graphql`
  query {
    csPetAboutYourPetV2 {
      next_button_text
    }
  }
`;

const AboutYourPetsForm: React.FC<AboutYourPetsFormProps> = ({ moveNext }) => {
  const {
    csPetAboutYourPetV2: { next_button_text },
  } = useStaticQuery<AboutYourPetFormData>(query);

  const [petsDetails, updatePetsDetails] = usePetsDetails();
  const petRules = useAboutYourPetRules();

  const formErrorRules = {
    ...petRules.errors,
  };

  const formWarningRules = {
    ...petRules.warnings,
  };

  const formDetails = {
    petsDetails,
  };

  const {
    getError,
    getWarning,
    showValidation,
    resetValidation,
    validateOnSubmit,
  } = useValidationWithWarnings<{ petsDetails: PetsDetails }>(
    formDetails,
    formErrorRules,
    formWarningRules,
    trackFieldError
  );

  const {
    defaultSectionHeadings,
    removePetButtonTexts,
    addPetButtonText,
    addAnotherPetBanner,
    threePetsAddedBanner,
    petSectionHeading,
    petSectionSubheading,
  } = usePetDetailsOptions();

  const generateSectionLabel = (index: number): string => {
    const { petName } = petsDetails[index];
    return petName
      ? petNameReplacer(petName, petSectionHeading)
      : defaultSectionHeadings[index];
  };

  const assumptions = useAssumptions();
  const [assumptionsAgreement] = useAssumptionsAgreement();

  const assumptionIdToFormFieldValidationMapping: Partial<Record<
    AssumptionId,
    () => void
  >> = {
    good_health: () => {
      petsDetails.forEach((_, i) => {
        showValidation('petsDetails', ['petInGoodHealth', i]);
      });
    },
    no_complaints_about_behaviour: () => {
      petsDetails.forEach((_, i) => {
        showValidation('petsDetails', ['eligibilityConditionsAgreement', i]);
      });
    },
    not_involved_in_legal_action: () => {
      petsDetails.forEach((_, i) => {
        showValidation('petsDetails', ['eligibilityConditionsAgreement', i]);
      });
    },
  };

  useEffect(() => {
    if (assumptions !== undefined && !assumptionsAgreement.assumptionsAgreed) {
      assumptions.assumptions.forEach(({ id }) =>
        assumptionIdToFormFieldValidationMapping[id]?.()
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { addPet, removePet } = useUpdateNumberOfPets();

  const [announcementText, makeAnnouncement] = useScreenReaderAnnouncement();

  return (
    <form onSubmit={validateOnSubmit(moveNext, scrollAndTrackError)}>
      <ScreenReaderAnnouncer announcementText={announcementText} />
      <AboutYourPetFormRepeatableSection
        onAdd={() => {
          trackTextButtonClick(PageTitle.AboutYourPet, addPetButtonText);
          addPet();
        }}
        onRemove={(index) => {
          trackTextButtonClick(PageTitle.AboutYourPet, removePetButtonTexts[index]);
          const updatedNumberOfPets = removePet(index);
          makeAnnouncement(`Pet ${index + 1} has been successfully removed`);
          if (updatedNumberOfPets === 1) {
            navigate('#add-pet-banner');
          } else if (updatedNumberOfPets === 2) {
            navigate(`#addItem${updatedNumberOfPets - 1}`);
          }
        }}
        // We disable the add button for 1 pet in order to use the alternate 'add pet banner' instead of the default button
        disableAdd={petsDetails.length >= 3 || petsDetails.length === 1}
        label={generateSectionLabel}
        labelSubheading={petSectionSubheading}
        labelHtmlTag="h2"
        addText={addPetButtonText}
        removeText={(index) => removePetButtonTexts[index]}
        alignLeft>
        {petsDetails.map((pet, index) => (
          <AboutYourPetForm
            key={pet.key}
            index={index}
            petDetails={pet}
            updatePetDetails={(update) =>
              updatePetsDetails(updateItem(petsDetails, index, update))
            }
            formValidation={{ getError, getWarning, showValidation, resetValidation }}
          />
        ))}
      </AboutYourPetFormRepeatableSection>
      {petsDetails.length === 1 && showAdditionalPetBanner(petsDetails[0]) && (
        <AboutYourPetFormAdditionalSectionBanner
          data-cy="addPetBanner"
          id="add-pet-banner"
          headingText={addAnotherPetBanner.headingText}
          bodyText={addAnotherPetBanner.bodyText}
          buttonText={addAnotherPetBanner.buttonText}
          buttonAriaLabel={addAnotherPetBanner.buttonScreenreaderText}
          pageTitle={PageTitle.AboutYourPet}
          buttonId="addPetButton"
          addFormSectionButtonClick={() => {
            addPet();
            /* This use of setTimeout forces scrollAndFocusInput to be called after
             * updatePetsDetails, so that we attempt to scroll to the newly added pet
             * form section *after* the section has been rendered in the DOM.
             *
             * See https://stackoverflow.com/questions/779379/why-is-settimeoutfn-0-sometimes-useful for more
             * details about this implementation.
             */
            setTimeout(() => scrollAndFocusInput(`petDetails[${petsDetails.length}]`));
            trackTextButtonClick(PageTitle.AboutYourPet, 'Add a pet - pet details');
          }}
        />
      )}
      {petsDetails.length === 3 && showAdditionalPetBanner(petsDetails[2]) && (
        <ThreePetAddedBannerSection
          data-cy="threePetsAddedBanner"
          headingText={threePetsAddedBanner.bannerHeadingText}
          bodyText={threePetsAddedBanner.bannerBodyText}
          pageTitle={PageTitle.AboutYourPet}
        />
      )}
      <FormFooter
        moveNextButton={{
          text: next_button_text,
          onClick: () => trackTextButtonClick(PageTitle.AboutYourPet, 'Submit details'),
        }}
        pageTitle={PageTitle.AboutYourPet}
      />
    </form>
  );
};

export default AboutYourPetsForm;
