import RadioInput from '@rsa-digital/evo-shared-components/components/Form/RadioInput';
import { FieldFunction } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import QuestionField from 'components/QuestionField';
import TypeaheadInput from 'components/TypeaheadInput';
import { DesignConstancts } from 'helpers/businessConstants';
import {
  PageTitle,
  trackFormDropdownFocus,
  trackFormDropdownSelect,
  trackRadioButtonClick,
} from 'helpers/eventTracking';
import { getAnalyticsDescriptionForInput } from 'helpers/getAnalyticsDescriptionForInput';
import getPetIdForInput from 'helpers/getPetId';
import { INPUT_REGEX_ALPHABETICAL_WITH_SPACE_HYPHEN_APOSTROPHE } from 'helpers/inputRegexes';
import { getOptimizelyVariation } from 'helpers/optimizely';
import { useAboutYourPetPlaceholders } from 'helpers/placeholders/aboutYourPetplaceholders';
import { replacePlaceholdersRichText } from 'helpers/placeholders/replaceCsPlaceholders';
import {
  catBreedType_NON_PEDIGREE,
  catBreedType_PEDIGREE,
  petType_CAT,
} from 'helpers/referenceDataConstants';
import { Pet, PetsDetails, PetWithKey } from 'state/formData/petsDetails';
import { ReferenceDataOption } from 'types/referenceData';
import { BreedNameInfoPanel, ShadowContainer } from './styles';
import { useCatBreedTypeOptions } from '../../useCatBreedTypeOptions';
import useAboutYourPetQuestions from '../questions';
import { BreedInfoRichTextWithModal } from '../styles';

type CatBreedQuestionsData = {
  csPetAboutYourPetCatQuestionsV2: {
    cat_breed_name_pedigree: {
      breed_info_panel_text: string;
    };
    cat_breed_name_non_pedigree: {
      breed_info_panel_text: string;
    };
  };
};

export const query = graphql`
  query {
    csPetAboutYourPetCatQuestionsV2 {
      cat_breed_name_pedigree {
        breed_info_panel_text
      }
      cat_breed_name_non_pedigree {
        breed_info_panel_text
      }
    }
  }
`;

type CatBreedQuestionsProps = {
  petDetails: Pet;
  updatePetDetails: (update: Partial<PetWithKey>) => void;
  index: number;
  formValidation: {
    getError: FieldFunction<{ petsDetails: PetsDetails }, string | undefined>;
    showValidation: FieldFunction<{ petsDetails: PetsDetails }, void>;
  };
  catBreedReferenceData: ReferenceDataOption[];
};

const CatBreedQuestions: React.FC<CatBreedQuestionsProps> = ({
  petDetails,
  updatePetDetails,
  index,
  formValidation: { getError, showValidation },
  catBreedReferenceData,
}) => {
  const {
    csPetAboutYourPetCatQuestionsV2: {
      cat_breed_name_pedigree,
      cat_breed_name_non_pedigree,
    },
  } = useStaticQuery<CatBreedQuestionsData>(query);

  const questions = useAboutYourPetQuestions(petDetails);

  const getIdForInput = getPetIdForInput(index);

  const catBreedTypeOptions = useCatBreedTypeOptions();

  const showCatQuestions = petDetails.petType === petType_CAT;
  const showPedigreeCatBreedNameQuestion =
    showCatQuestions && petDetails.catBreedType === catBreedType_PEDIGREE;
  const showNonPedigreeCatBreedNameQuestion =
    showCatQuestions && petDetails.catBreedType === catBreedType_NON_PEDIGREE;

  const menuMaxHeight = DesignConstancts.PET_BREED_DROPDOWN_MENU_MAX_HEIGHT;
  // Dynamically set petDetails property key to use for cat breed data:
  // - Pedigree     => petDetails.catPedigreeBreedName
  // - Non Pedigree => petDetails.catCrossBreedName
  const catBreedNamePropertyKey = showPedigreeCatBreedNameQuestion
    ? 'catPedigreeBreedName'
    : 'catNonPedigreeBreedName';

  const breedNameQuestion = showPedigreeCatBreedNameQuestion
    ? questions.pedigreeCatBreedName
    : questions.nonPedigreeCatBreedName;

  const breedNameAnalyticsDescription = showPedigreeCatBreedNameQuestion
    ? 'Pedigree cat breed name'
    : 'Non pedigree cat breed name';

  const catBreedNameMappedOptions = catBreedReferenceData.map((option) => ({
    id: option.value,
    label: option.name,
  }));

  /* Filtering moggy and moggie out of the list of pedigree breeds to prevent a user selecting a 
   pedigree moggy */
  const catBreedPedigreeMappedOptions = catBreedNameMappedOptions.filter(
    (breed) => breed.label !== 'Moggy' && breed.label !== 'Moggie'
  );

  const aboutYourPetPlaceholders = useAboutYourPetPlaceholders();
  const substitutePetPlaceholders = replacePlaceholdersRichText(
    aboutYourPetPlaceholders,
    petDetails
  );

  const catBreedInfoBoxText =
    petDetails.catBreedType === catBreedType_PEDIGREE
      ? substitutePetPlaceholders(cat_breed_name_pedigree.breed_info_panel_text)
      : substitutePetPlaceholders(cat_breed_name_non_pedigree.breed_info_panel_text);

  const showBreedInfoPanel = !!petDetails[catBreedNamePropertyKey];

  // ========================================================================================
  // =       Optimizely A/B Experiment - JLABTestBreedFieldDesign          =
  // ========================================================================================
  const optimizelyJLOnlyVariation = getOptimizelyVariation(
    process.env.GATSBY_OPTIMIZELY_BREED_LIST_EXPERIMENT_NAME
  );
  const isOptimizelyNewVariation = optimizelyJLOnlyVariation === 'Variation #1';

  // - If not isOptimizelyNewVariation  => We show existing variation
  // - If isOptimizelyNewVariation      => We show JL Only variation

  /* This question field will be rendered on two separate occasions:
  When a user selects 'pedigree' and when a user selects 'non-pedigree'
  Rendering the question field each time will clear the field when changing breed type as is required,
  as well as animating the field to make it clear the user needs to fill it in again' */

  const breedNameQuestionField = (
    <>
      {isOptimizelyNewVariation ? (
        <ShadowContainer>
          <QuestionField
            question={breedNameQuestion}
            errorText={getError('petsDetails', [catBreedNamePropertyKey, index])}>
            <TypeaheadInput
              id={getIdForInput(catBreedNamePropertyKey)}
              value={catBreedNameMappedOptions.find(
                (option) => option.id === petDetails[catBreedNamePropertyKey]
              )}
              options={
                (petDetails.catBreedType === catBreedType_PEDIGREE
                  ? catBreedPedigreeMappedOptions
                  : catBreedNameMappedOptions) ?? []
              }
              placeholder={breedNameQuestion.placeholder}
              showChevron
              menuMaxHeight={menuMaxHeight}
              onChange={(option) => {
                updatePetDetails({
                  [catBreedNamePropertyKey]: option?.id,
                });
                trackFormDropdownSelect(
                  getAnalyticsDescriptionForInput(index, breedNameAnalyticsDescription),
                  option?.label ?? 'none'
                );
              }}
              onBlur={() => {
                showValidation('petsDetails', [catBreedNamePropertyKey, index]);
              }}
              onFocus={trackFormDropdownFocus(
                getAnalyticsDescriptionForInput(index, breedNameAnalyticsDescription),
                PageTitle.AboutYourPet
              )}
              onKeyPress={(e) => {
                if (!e.key.match(INPUT_REGEX_ALPHABETICAL_WITH_SPACE_HYPHEN_APOSTROPHE)) {
                  e.preventDefault();
                }
              }}
            />
          </QuestionField>
        </ShadowContainer>
      ) : (
        <QuestionField
          question={breedNameQuestion}
          errorText={getError('petsDetails', [catBreedNamePropertyKey, index])}>
          <TypeaheadInput
            id={getIdForInput(catBreedNamePropertyKey)}
            value={catBreedNameMappedOptions.find(
              (option) => option.id === petDetails[catBreedNamePropertyKey]
            )}
            options={
              (petDetails.catBreedType === catBreedType_PEDIGREE
                ? catBreedPedigreeMappedOptions
                : catBreedNameMappedOptions) ?? []
            }
            placeholder={breedNameQuestion.placeholder}
            menuMaxHeight={menuMaxHeight}
            onChange={(option) => {
              updatePetDetails({
                [catBreedNamePropertyKey]: option?.id,
              });
              trackFormDropdownSelect(
                getAnalyticsDescriptionForInput(index, breedNameAnalyticsDescription),
                option?.label ?? 'none'
              );
            }}
            onBlur={() => showValidation('petsDetails', [catBreedNamePropertyKey, index])}
            onFocus={trackFormDropdownFocus(
              getAnalyticsDescriptionForInput(index, breedNameAnalyticsDescription),
              PageTitle.AboutYourPet
            )}
            onKeyPress={(e) => {
              if (!e.key.match(INPUT_REGEX_ALPHABETICAL_WITH_SPACE_HYPHEN_APOSTROPHE)) {
                e.preventDefault();
              }
            }}
          />
        </QuestionField>
      )}
    </>
  );

  return (
    <>
      <QuestionField
        question={questions.catBreedType}
        errorText={getError('petsDetails', ['catBreedType', index])}
        data-cy="catBreedTypeField"
        initiallyShowTooltip>
        <RadioInput
          id={getIdForInput('catBreedType')}
          value={petDetails.catBreedType}
          options={catBreedTypeOptions}
          onChange={(e) => {
            updatePetDetails({
              catBreedType: e.target.value,
              [catBreedNamePropertyKey]: '',
            });
            trackRadioButtonClick(
              getAnalyticsDescriptionForInput(index, 'Cat breed type'),
              e.target.value
            );
          }}
        />
      </QuestionField>
      {showPedigreeCatBreedNameQuestion && breedNameQuestionField}
      {showNonPedigreeCatBreedNameQuestion && breedNameQuestionField}
      {showBreedInfoPanel && (
        <BreedNameInfoPanel>
          <BreedInfoRichTextWithModal
            html={catBreedInfoBoxText}
            pageTitle={PageTitle.AboutYourPet}
          />
        </BreedNameInfoPanel>
      )}
    </>
  );
};

export default CatBreedQuestions;
